<template>
	<a-row type="flex" :gutter="24">
        <a-col :span="24" :md="17" class="mb-20">
            <a-card :bordered="false" class="mb-15">
                <template #title>
                    <h6 class="font-semibold m-0">{{ $t('server.snapshots.title') }}</h6>
                </template>
                <a-form :form="snapshotInfo" :label-col="{ span: 24 }" :wrapper-col="{ span: 16 }" @submit="handleSubmit">
                    <a-alert class="mb-10" show-icon :message="$t('server.snapshots.desc')" type="info" />
                    <a-form-item :label="$t('server.snapshots.name')">
                        <a-input
                            v-decorator="['name', { rules: rules.name }]"
                        />
                    </a-form-item>
                    <a-form-item :label="$t('server.snapshots.selectVolume')">
                        <a-select
                            :options="volumes"
                            v-decorator="['volume_id', { rules: rules.volume_id}]"
                            @change="selectVolume"
                            :loading="loadingVolume"
                        >
                        </a-select>
                    </a-form-item>
                    <WidgetSelectTerm
                        :params="form.config"
                        :type="form.type"
                        v-model="WidgetSelectTermData"
                    />
                    <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                        <a-button 
                            type="primary" 
                            html-type="submit"
                            :loading="fetchCreateOrder"
                            >{{ $t('server.snapshots.btnCreate') }}
                        </a-button>
                    </a-form-item> 
                </a-form>
            </a-card>
        </a-col>
		<a-col :span="24" :md="7" class="mb-24">
		</a-col>
	</a-row>
</template>

<script>

    import WidgetSliderConfig from "@/components/Widgets/WidgetSliderConfig"
    import WidgetSelectTerm from "@/components/Widgets/WidgetSelectTerm"
    import OrderService from '@/services/order'
    import { notification } from 'ant-design-vue'
    import volumes from "@/services/volume/listing"
    import {PATTERN_NAME, ORDER_TYPE_SNAPSHOT, STORAGE_UNIT} from "@/constant"

	export default ({
		components: {
            WidgetSliderConfig,
            WidgetSelectTerm,
		},
		data() {
			return {
                form: {
                    type: ORDER_TYPE_SNAPSHOT,
                    config: {
                        disk: 0
                    },
                    info: {},
                    interval: ''
                },
                snapshotInfo: this.$form.createForm(this, { name: 'snapshotInfo' }),
                rules: {
                    name: [
                        { required: true, message: this.$t('rulesForm.requiredSnapshotName'), trigger: ['blur', 'change'] },
                        { min: 5, max: 32, message: this.$t('rulesForm.invalidSnapshotCharactersLimited'), trigger: ['blur', 'change'] },
                        { pattern: PATTERN_NAME,
                        message: this.$t('rulesForm.invalidSnapshotName'),
                        trigger: ['blur', 'change']
                        }
                    ],
                    volume_id: [
                        { required: true, message: this.$t('rulesForm.requiredVolumeIdSnapshot'), trigger: ['blur', 'change'] }
                    ]
                },
                WidgetSelectTermData: {},
                fetchCreateOrder: false,
                loadingVolume: true,
                volumes: [],
                raw_volumes: [],
			}
		},
        watch: {
            WidgetSelectTermData: function (val) {
                this.form.interval = val.interval
            }
        },
        created() {
			volumes()
			.then(res=> {
				if (this._.isObject(res)) {
                    this._.forEach(res, (row)=> {
                        this.volumes.push({
                            label:  `${row.name} (${row.volume_type.toUpperCase()} ${row.size} ${STORAGE_UNIT})`,
                            value: row.id
                        })
                    })
                    this.loadingVolume = false
                    this.raw_volumes = this._.clone(res)
				}
			})
		},
        methods : {
            selectVolume(v) {
                let volume = this._.find(this.raw_volumes, function(row) { return row.id === v })
                this.form.config = {
                    disk: volume.size,
                    disk_type: volume.volume_type
                }
                console.log(this.form)
            },
            handleSubmit(e) {
                e.preventDefault();
                this.snapshotInfo.validateFields((err, values) => {
                    if (err) {
                        return;
                    }
                    console.log(this.form)
                    this.form.info.name = values.name
                    this.form.info.volume_id    = values.volume_id

                    let messages = []
                    
                    if (this.form.interval === '')
                        messages.push('Bạn chưa chọn kỳ hạn thanh toán')
                    
                    if (!this._.isEmpty(messages)) {
                        this._.forEach(messages, val => {
                            notification.error({
                                message: val
                            })
                        })
                        return
                    }

                    this.createOrder()
                });
            },
            createOrder() {
                this.fetchCreateOrder = true
                OrderService.create(this.form)
                .then(res => {
                    this.fetchCreateOrder = false
                    if (this._.isObject(res) && res.status == 1) {
                        notification.success({
                            message: 'Tạo đơn hàng thành công'
                        })
                        this.$router.push({name: 'billing.payment', params: {id: res.data.invoice_id}})
                    } else {
                        notification.error({
                            message: res.message
                        })
                    }
                })
            }
        }
	})

</script>

<style lang="scss">
</style>